import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

import style from 'style';
import { AnalyticsAPI, ProviderAPI, useGetApi } from 'providerSite/api';
import Spinner from 'components/Spinner';


const Container = styled.div`
  padding: 5px 20px 10px 20px;
  min-height: 231px;
  font-size: 13px;
  
  .section-title {
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .flex-container {
    display: flex;
    column-gap: 20px;
  }
`;

const DataItemContainer = styled.div`
  display: flex;
  column-gap: 50px;
  row-gap: 5px;
  flex-wrap: wrap;
  min-width: 600px;
`;

const Button = styled(AntdButton)`
  font-size: 12px;
  border-radius: 8px;
`;


const ExpandedRow = ({ providerUuid }) => {

  const { isLoading, data: _data } = useGetApi(ProviderAPI.GET_PROVIDER_FOR_OPERATION, {
    params: {
      uuid: providerUuid,
    },
  });

  const { data: _statsData, isLoading: isStatsDataLoading } = useGetApi(AnalyticsAPI.GET_PROVIDER_VALUES, {
    params: {
      providerUuid: providerUuid,
    },
    queryParams: {
      period: '30_days',
    },
  });

  const data = _data ? _data : {};
  const statsData = _statsData ? _statsData : [];

  const renderStats = () => (
    <DataItemContainer>
      {statsData.map((item, idx) => (
        <Data
          key={idx}
          title={_.startCase(item.title.replaceAll('_', ' '))}
          width="120px"
        >
          {item.value}
        </Data>
      ))}
    </DataItemContainer>
  );

  const renderDetails = () => (
    <DataItemContainer>
      <Data title="Name">
        {data.name}
      </Data>
      <Data title="Slug">
        {data.slug}
      </Data>
      <Data title="UUID">
        {data.uuid}
      </Data>
      <Data title="Fresh Reviews">
        {data.freshReviewCount}
      </Data>
      <Data title="Total Responses">
        {data.totalResponseCount}
      </Data>
      <Data title="Total Users">
        {data.userCount}
      </Data>
    </DataItemContainer>
  );

  return (
    <Container>
      <div className="flex-container">
        <div>
          <div className="section-title">
            Provider Details
          </div>
          {isLoading ? <Spinner /> : renderDetails()}
        </div>
        <div>
          <div className="section-title">
            Analytics (Last 30 days)
          </div>
          {isStatsDataLoading ? <Spinner /> : renderStats()}
        </div>
      </div>
      <div className="flex-container">
        <Button
          size="small"
          type="primary"
          onClick={() => window.open(`/provider/${data.uuid}/admin/listings`, '_blank')}
          disabled={!data}
        >
          Profile
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => window.open(`https://clicka.zendesk.com/agent/organizations/${data.supportCrmRef}/tickets`, '_blank')}
          disabled={!data.supportCrmRef}
        >
          Zendesk
        </Button>
      </div>
    </Container>
  );
};

ExpandedRow.propTypes = {
  providerUuid: PropTypes.string.isRequired,
};


const DataContainer = styled.div`
  margin-top: auto;
  width: ${({width}) => width && width};
  
  .title {
    font-weight: 500;
    color: ${style.colors.palette.battleshipGrey};
    margin-bottom: ${({extraGap}) => extraGap ? '2px' : '-1px'};
    margin-left: ${({extraGap}) => extraGap && '2px'};
    font-size: 11px;
  }
  
  .ellipsis {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  },
`;

const Data = ({children, title, extraGap, ellipsis, width}) => {
  return (
    <DataContainer extraGap={extraGap} width={width}>
      <div className="title">
        {title}
      </div>
      <div className={ellipsis && 'ellipsis'}>
        {children}
      </div>
    </DataContainer>
  );
};

Data.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  extraGap: PropTypes.bool,
  ellipsis: PropTypes.bool,
  width: PropTypes.string,
};


export default ExpandedRow;

