import React from 'react';

import { Tag, Rate } from 'antd';
import dayjs from 'dayjs';

import DateTimeFrom from 'components/DateTimeFrom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import style from 'style';


const columns = [
  {
    key: 'name',
    dataIndex: 'name',
    title: <div style={{marginLeft: '15px'}}>Name</div>,
    width: '200px',
    ellipsis: true,
    render: value => {
      return (
        <div
          style={{
            fontWeight: 500,
            marginLeft: '15px',
          }}
        >
          {value}
        </div>
      );
    },
  },
  {
    key: 'subscription',
    dataIndex: 'subscription',
    title: 'Subscription',
    width: '135px',
    align: 'center',
    filters: [
      {
        text: 'Starter',
        value: 'Starter',
      },
      {
        text: 'Accreditation Plus',
        value: 'Accreditation Plus',
      },
      {
        text: 'Enterprise',
        value: 'Enterprise',
      },
      {
        text: 'Accreditation',
        value: 'Accreditation',
      },
      {
        text: 'Free',
        value: 'Free',
      },
    ],
    filterMultiple: false,
    render: value => {
      let color = null;

      if (value === 'Starter')
        color = 'green';
      else if (value === 'Accreditation Plus')
        color = 'blue';
      else if (value === 'Enterprise')
        color = 'gold';
      else if (value === 'Accreditation')
        color = 'magenta';

      return <Tag color={color}>{value}</Tag>;
    },
  },
  {
    key: 'billingCycle',
    dataIndex: 'billingCycle',
    title: 'Type',
    align: 'center',
    filters: [
      {
        text: 'Annual',
        value: 'annual',
      },
      {
        text: 'Quarterly',
        value: 'quarterly',
      },
      {
        text: 'Monthly',
        value: 'monthly',
      },
    ],
    filterMultiple: false,
    render: value => {
      if (!value) return '-';

      let color = null;

      if (value === 'Annual')
        color = 'cyan';
      else if (value === 'Quarterly')
        color = 'orange';
      else if (value === 'Monthly')
        color = 'magenta';

      return <Tag color={color}>{value}</Tag>;
    },
  },
  {
    key: 'accreditedUntil',
    dataIndex: 'accreditedUntil',
    title: 'Subscription End Date',
    align: 'center',
    width: '135px',
    sorter: true,
    render: value => {
      if (!value) return '-';

      const date = dayjs(value).format('ddd, MMM DD YYYY');

      return (
        <div>
          <div style={{fontSize: '13px'}}>{date}</div>
          <Tag style={{margin: 0}}><DateTimeFrom value={value} /></Tag>
        </div>
      );
    },
  },
  {
    key: 'reviewCount',
    dataIndex: 'reviewCount',
    title: 'Reviews',
    width: '110px',
    align: 'center',
    render: (_, record) => {

      return (
        <>
          <div style={{display: 'flex', justifyContent: 'center', columnGap: '15px'}}>
            <Tag
              style={{
                margin: 0,
                fontSize: '10px',
                lineHeight: '16px',
                padding: '0 4px'
              }}
              color={record.rating >= 4 ? 'green' : 'gold'}
            >
              {record.rating ? record.rating : 0}
            </Tag>
            <div style={{fontSize: '12px'}}>
              <FontAwesomeIcon
                style={{
                  fontSize: '13px',
                  color: style.colors.palette.dustyOrange,
                  marginRight: '4px',
                  verticalAlign: '-2px'
                }}
                icon={faCommentLines}
              />
              {record.reviewCount}
            </div>
          </div>
          <Rate style={{fontSize: '10px'}} disabled allowHalf value={record.rating} />
        </>
      );
    },
  },
  {
    key: 'activeListings',
    dataIndex: 'activeListings',
    title: 'Active Services',
    align: 'center',
  },
  {
    key: 'credits',
    dataIndex: 'credits',
    title: 'Credits',
    align: 'center',
  },
  {
    key: 'totalAcceptedSr',
    dataIndex: 'totalAcceptedSr',
    title: 'Total Accepted SR',
    align: 'center',
    render: value => {
      return <Tag>{value}</Tag>;
    },
  },
  {
    key: 'thisMonthAcceptedSr',
    dataIndex: 'thisMonthAcceptedSr',
    title: 'Accepted SR',
    align: 'center',
    render: value => {
      let color = null;
      if (value < 2)
        color = 'error';
      else if (value < 3)
        color = 'warning';
      else
        color = 'success';

      return <Tag color={color}>{value}</Tag>;
    },
  },
  {
    key: 'thisMonthSrNotification',
    dataIndex: 'thisMonthSrNotification',
    title: 'SR Notification',
    align: 'center',
    render: value => {
      let color = null;
      if (value < 10)
        color = 'error';
      else if (value < 15)
        color = 'warning';
      else
        color = 'success';

      return <Tag color={color}>{value}</Tag>;
    },
  },
  {
    key: 'lastLogin',
    dataIndex: 'lastLogin',
    title: 'Last Login',
    align: 'center',
    width: '120px',
    render: value => {
      if (!value) return '-';
      const today = dayjs();
      const daysAgo = today.diff(dayjs(value), 'day');

      let color = null;
      if (daysAgo >= 7)
        color = 'error';
      else if (value >= 3)
        color = 'warning';

      return <Tag color={color}><DateTimeFrom value={value} /></Tag>;
    },
  },
];

export default columns;
