import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from 'style';


const Main = styled.div`
  scroll-margin-top: 20px;
`;

const Header = styled.div`
  margin-left: 5px;
  font-weight: 400;
  font-size: 16px;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  column-gap: 7px;
  .icon {
    font-size: 20px;
    margin-bottom: 2px;
  }
`;

const Container = styled.div`
  border-radius: ${style.borderRadius.large};
  border: 1px solid ${style.colors.lightBorder};
  box-shadow: ${style.shadow(15)};
`;

const ChildrenContainer = styled.div`
  padding: 20px;
  max-height: ${({ isMaximised, collapsableHeight }) => (
    isMaximised ? '10000px' : collapsableHeight
  )};
  overflow: hidden;
  transition: max-height 0.5s;
`;

const SeeMoreButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 20px;
  padding-bottom: 10px;
`;

const SeeMoreButton = styled.div`
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  color: ${style.colors.secondary};
  :hover {
    color: ${style.colors.primary};
  }
`;

const Card = (
  {
    children,
    id,
    title,
    icon,
    isLoading,
    loadingRows=3,
    noData,
    collapsable,
    collapsableHeight='300px',
    collapsableButtonText='See more...',
    ...props
  }) => {

  const [isMaximised, setIsMaximised] = useState();

  useEffect(() => {
    setIsMaximised(!collapsable);
  }, [collapsable]);

  return (
    <Main id={id}>
      {title && (
        <Header>
          {icon && <FontAwesomeIcon className="icon" icon={icon} />}
          {title}
        </Header>
      )}
      <Container {...props}>
        <ChildrenContainer
          isMaximised={isMaximised}
          collapsableHeight={collapsableHeight}
        >
          {isLoading ? (
            <Skeleton paragraph={{rows: loadingRows}} active style={{marginTop: '10px'}} />
          ) : (
            noData ? <>Not Available</> : children
          )}
        </ChildrenContainer>
        {!isMaximised && (
          <SeeMoreButtonContainer>
            <SeeMoreButton onClick={() => setIsMaximised(true)}>
              {collapsableButtonText}
            </SeeMoreButton>
          </SeeMoreButtonContainer>
        )}
      </Container>
    </Main>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  icon: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingRows: PropTypes.number,
  noData: PropTypes.bool,
  id: PropTypes.string,
  collapsable: PropTypes.bool,
  collapsableHeight: PropTypes.string,
  collapsableButtonText: PropTypes.string,
};


export default Card;

