import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import * as track from 'lib/track';
import adAsset from 'assets/youfoodz-banner.png';


const useStyles = makeStyles({
  root: {
    width: '100%',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '16px',
    marginBottom: '30px',
    filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2))',
  },
  image: {
    maxWidth: '100%',
    objectFit: 'contain',
  },
});

const DirectoryAdCTA = () => {
  const classes = useStyles();

  const onClick = () => {
    const url = 'https://care.youfoodz.com/?utm_source=partnerships&utm_medium=directory&utm_campaign=clickability_directory';
    track.sendEvent('Directory Ad CTA banner - Youfoodz', 'click');
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root} onClick={onClick}>
      <img src={adAsset} className={classes.image} alt="YouFoodz" />
    </div>
  );
};

export default DirectoryAdCTA;
