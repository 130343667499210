import React, { useState } from 'react';

import styled from 'styled-components';
import { Table as AntdTable, Input, DatePicker, Switch } from 'antd';
import { snakeCase } from 'snake-case';

import Page from 'components/Page';
import style from 'style';
import { ProviderAPI, useGetApi } from 'providerSite/api';
import columns from './columns';
import withAdminOnly from '../../../siteadmin/components/AdminOnly';
import ExpandedRow from './ExpandedRow';


const { Search } = Input;


const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${style.sizes.large};

  .row {
    cursor: pointer;
    :hover {
      background: rgb(243, 251, 255);
    }
  }

  .row-even {
    background: #f9f9f9;
  }
`;

const OuterFilterContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  
  .date-filter-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
  }
`;

const Table = styled(AntdTable)`
  min-height: 842px;
  margin-bottom: 40px;
  box-shadow: ${style.shadow(15)};
  border-radius: 18px;
  overflow: hidden;
  
  table {
    overflow: hidden;
  }
  
  && tbody > tr:hover > td {
    background: unset;
  }
  
  .ant-table-tbody > tr > td {
    border-bottom: none;
    transition: none;
  }
  
  .ant-table-row {
    border-bottom: none;
  }
  
  .ant-table-expanded-row {
    background: #f6f3ec;
    .ant-table-cell {
      background: #f6f3ec;
      border-top: 1px solid #e9d1ad;
      border-bottom: 1px solid #e9d1ad;
    }
  }
  
  .ant-table-thead > tr > th {
    font-size: 13px;
  }
  
`;

const ProviderDashboard = () => {

  const [ filters, setFilters ] = useState({});
  const [ order, setOrder ] = useState(null);
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(20);

  const [ excludeFree, setExcludeFree ] = useState(true);

  const { isLoading, data: _data } = useGetApi(ProviderAPI.GET_ALL_PROVIDERS_FOR_OPERATION, {
    queryParams: {
      page,
      pageSize,
      ...filters,
      orderBy: order,
      searchText: search,
      excludeFree: excludeFree,
    },
  });

  const data = _data ? _data.data : [];
  const metaData = _data ? _data.metaData : {};


  const handleChange = (pagination, filters, sorter, { action }) => {

    if (action === 'paginate') {
      setPage(pagination.current);
      setPageSize(pagination.pageSize);
    }

    if (action === 'filter') {
      setFilters(filters);
    }

    if (action === 'sort') {
      if (!sorter.order) {
        setOrder(null);
        return;
      }
      let orderSign = '';
      if (sorter.order === 'descend')
        orderSign = '-';

      setOrder(orderSign + snakeCase(sorter.field));
    }
  };

  const onDateChange = (_, dateString) => {
    setFilters(prevState => ({...prevState, subscriptionTill: dateString}));
  };

  return (
    <Page title="Provider Dashboard">
      <Main>
        <Container>
          <OuterFilterContainer>
            <Search
              placeholder="Provider Name or UUID"
              onSearch={v => setSearch(v)}
              style={{
                width: 400,
              }}
            />
            <div className="date-filter-container">
              <div>
                Subscription Ends:
              </div>
              <DatePicker onChange={onDateChange} />
            </div>
            <Switch
              checkedChildren="Free Excluded"
              unCheckedChildren="Free Included"
              checked={excludeFree}
              onChange={checked => setExcludeFree(checked)}
            />
          </OuterFilterContainer>
          <Table
            rowClassName={(_, index) => index % 2 === 0 ? 'row row-even' : 'row'}
            dataSource={data}
            columns={columns}
            size="small"
            pagination={{
              pageSize: pageSize,
              total: metaData.count,
              position: ['bottomCenter'],
            }}
            expandable={{
              expandRowByClick: true,
              showExpandColumn: false,
              rowExpandable: () => true,
              expandedRowRender: record => <ExpandedRow key={record.id} providerUuid={record.uuid} />,
            }}
            onChange={handleChange}
            loading={isLoading}
          />
        </Container>
      </Main>
    </Page>
  );
};


export default withAdminOnly(ProviderDashboard);
